import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { User, UserAdditionalData } from "src/services/user/user.interface";
import { UserService } from "src/services/user/user.service";
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from "rxjs";
import { isPlatform } from "@ionic/angular";
import { Facebook } from "@ionic-native/facebook/ngx";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
import { UserAlreadyExistsError } from "../user/errors/user-already-exists.error";
import { Role } from "src/services/user/role.enum";
import { HttpService } from "src/services/http/http.service";
import { environment } from "@environments/environment";
import { getAuth } from "@angular/fire/auth";
import firebase from "firebase/compat/app";

export enum AuthenticationProvidersApiName {
  apple = "apple.com",
  emailPassword = "password",
  facebook = "facebook.com",
  google = "google.com",
}

export enum AuthenticationProvidersDisplayName {
  apple = "Sign in with Apple",
  emailPassword = "email and password",
  facebook = "Log in with Facebook",
  google = "Log in with Google",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private activeUser: User;
  private activeUserSub: Subscription;
  private readonly activeUserObservable: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  constructor(
    private readonly apple: SignInWithApple,
    private readonly facebook: Facebook,
    private readonly firebaseAuth: AngularFireAuth,
    private readonly google: GooglePlus,
    private readonly httpService: HttpService,
    private readonly userService: UserService,
  ) {}

  public static async getErrorMessageToDisplay(error: any, userEmail: string): Promise<string> {
    const otherProvider = await AuthService.getOtherSignInMethodsForEmail();

    if (error instanceof UserAlreadyExistsError) {
      return "A user with this email already exists. If this is your first time logging in, check your inbox for instructions on how to create your password.";
    }

    if (
      (error.code === "auth/wrong-password" && otherProvider === AuthenticationProvidersDisplayName.emailPassword) ||
      !otherProvider
    ) {
      return "You have entered an incorrect password, please check it and try again.";
    } else if (error.code === "auth/wrong-password" || error.code === "auth/account-exists-with-different-credential") {
      return `You previously logged in using ${otherProvider}. Use this same authentication method again to gain access to your account.`;
    } else if (error.code === "auth/invalid-email") {
      return "You have entered an invalid email, please check it and try again.";
    } else if (error.code === "auth/user-not-found") {
      return "This email does not exist.";
    } else {
      return `An unexpected error occurred: ${error.message ?? error.localizedDescription ?? error}`;
    }
  }

  private static providerIdToDisplayName(providerId: string): string {
    switch (providerId) {
      case AuthenticationProvidersApiName.emailPassword: {
        return AuthenticationProvidersDisplayName.emailPassword;
      }
      case AuthenticationProvidersApiName.apple: {
        return AuthenticationProvidersDisplayName.apple;
      }
      case AuthenticationProvidersApiName.facebook: {
        return AuthenticationProvidersDisplayName.facebook;
      }
      case AuthenticationProvidersApiName.google: {
        return AuthenticationProvidersDisplayName.google;
      }
      default: {
        return providerId;
      }
    }
  }

  private static async getOtherSignInMethodsForEmail(): Promise<AuthenticationProvidersDisplayName> {
    const providerId = getAuth().currentUser?.providerId;
    return AuthService.providerIdToDisplayName(providerId) as AuthenticationProvidersDisplayName;
  }

  public loginWithEmailUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  public async loginWithApple(onSuccess: () => void | Promise<void>): Promise<void> {
    let userCredentials: firebase.auth.UserCredential;

    try {
      if (isPlatform("ios") && !isPlatform("mobileweb")) {
        userCredentials = await this.loginWithAppleIos();
      } else {
        userCredentials = await this.loginWithAppleWeb();
      }

      await this.onLoginSuccess(userCredentials, onSuccess);
    } catch (err) {
      if (!this.shouldIgnoreError(err)) {
        throw err;
      }
    }
  }

  public async loginWithFacebook(onSuccess: () => void | Promise<void>): Promise<void> {
    let userCredentials: firebase.auth.UserCredential;

    try {
      if ((isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb")) {
        userCredentials = await this.loginWithFacebookIos();
      } else {
        userCredentials = await this.loginWithFacebookWeb();
      }

      await this.onLoginSuccess(userCredentials, onSuccess);
    } catch (err) {
      if (!this.shouldIgnoreError(err)) {
        throw err;
      }
    }
  }

  public async loginWithGoogle(onSuccess: () => void | Promise<void>): Promise<void> {
    let userCredentials: firebase.auth.UserCredential;

    try {
      if ((isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb")) {
        userCredentials = await this.loginWithGoogleIos();
      } else {
        userCredentials = await this.loginWithGoogleWeb();
      }

      await this.onLoginSuccess(userCredentials, onSuccess);
    } catch (err) {
      if (!this.shouldIgnoreError(err)) {
        throw err;
      }
    }
  }

  public async registerByEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const existingUser = await firstValueFrom(this.userService.getUserByEmail(email));

    if (existingUser) {
      throw new UserAlreadyExistsError(email);
    }

    return this.firebaseAuth.createUserWithEmailAndPassword(email, password);
  }

  public async loginComplete(userId: string, onSuccess?: () => void | Promise<void>): Promise<void> {
    this.activeUser = await firstValueFrom(this.userService.getById(userId));

    if (!!onSuccess) {
      await onSuccess();
    }

    this.httpService
      .sendPostRequest(environment.endpoints.createReferralCoupon, { userId: userId })
      .catch((error) => console.error(error));
  }

  public getActiveUser(): Promise<User> {
    if (!!this.activeUser) {
      return Promise.resolve(this.activeUser);
    }

    return new Promise((resolve, _) => {
      this.firebaseAuth.user.pipe().subscribe(async (response) => {
        if (response === null) {
          this.activeUserSub?.unsubscribe();
          this.activeUserSub = undefined;

          this.activeUser = undefined;
          resolve(undefined);
        } else {
          const uid = response.uid;
          this.activeUser = await firstValueFrom(this.userService.getById(uid));
          resolve(this.activeUser);
          this.activeUserSub = this.userService.getById(uid).subscribe((user) => {
            this.activeUser = user;
            this.activeUserObservable.next(this.activeUser);
          });
        }
      });
    });
  }

  public async isActiveUserAdmin(): Promise<boolean> {
    return this.userHasRole([Role.admin]);
  }

  public async isActiveUserDriver(): Promise<boolean> {
    return this.userHasRole([Role.driver]);
  }

  public async isActiveUserAdminOrDriver(): Promise<boolean> {
    return this.userHasRole([Role.admin, Role.driver]);
  }

  public getActiveUserObservable(): Observable<User> {
    return this.activeUserObservable;
  }

  public async logout(onSuccess?: () => void | Promise<void>) {
    this.firebaseAuth.signOut().then(async () => {
      this.activeUserSub?.unsubscribe();
      this.activeUserSub = undefined;
      this.activeUser = undefined;

      await onSuccess?.();
    });
  }

  public async logoutSync(onSuccess?: () => void | Promise<void>): Promise<void> {
    await this.firebaseAuth.signOut();

    this.activeUserSub?.unsubscribe();
    this.activeUserSub = undefined;
    this.activeUser = undefined;

    await onSuccess?.();
  }

  public resetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  public async createUserIfNotExist(
    user: firebase.User,
    additionalData: UserAdditionalData | undefined,
    throwOnUserAlreadyExists: boolean,
    onSuccess: () => void | Promise<void>,
  ) {
    const existingUser = await firstValueFrom(this.userService.getUserByEmail(user.email));

    if (!!existingUser && throwOnUserAlreadyExists) {
      throw new UserAlreadyExistsError(user.email);
    }

    if (!existingUser) {
      const newUser = {
        id: user.uid,
        role: Role.customer,
        createdAt: new Date(),
        email: user.email,
        firstName: additionalData?.firstName,
        lastName: additionalData?.lastName,
        company: additionalData?.company ?? "",
        phone: additionalData?.phoneNumber,
      } as User;

      await this.userService.set(newUser);
    }

    await this.loginComplete(user.uid, onSuccess);
  }

  public async savePushToken(token: string): Promise<void> {
    await this.getActiveUser();

    this.activeUser.pushToken = token;
    await this.userService.update(this.activeUser.id, this.activeUser);
  }

  public extractAdditionalDataFromUser(user: firebase.auth.UserCredential): UserAdditionalData {
    const nameParts = user.user?.displayName?.split(" ");
    return {
      firstName: nameParts.length === 2 ? nameParts[0] : user?.user?.displayName,
      lastName: nameParts.length === 2 ? nameParts[1] : user?.user?.displayName,
      company: "",
      phoneNumber: user?.user?.phoneNumber,
      providerId: user?.user.providerData?.[0].providerId,
    };
  }

  private async loginWithAppleWeb(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    return this.firebaseAuth.signInWithPopup(provider);
  }

  private async loginWithFacebookWeb(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return await this.firebaseAuth.signInWithPopup(provider);
  }

  private async loginWithGoogleWeb(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return await this.firebaseAuth.signInWithPopup(provider);
  }

  private async loginWithAppleIos(): Promise<firebase.auth.UserCredential> {
    const response = await this.apple.signin({ requestedScopes: [0, 1] });
    const provider = new firebase.auth.OAuthProvider("apple.com");
    const credentials = provider.credential(response.identityToken);
    return this.firebaseAuth.signInWithCredential(credentials);
  }

  private async loginWithFacebookIos(): Promise<firebase.auth.UserCredential> {
    await this.facebook.setApplicationId("668143434276536");
    await this.facebook.setApplicationName("Rocket Closet");

    const response = await this.facebook.login(["email", "public_profile"]);
    const credentials = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
    return this.firebaseAuth.signInWithCredential(credentials);
  }

  private async loginWithGoogleIos(): Promise<firebase.auth.UserCredential> {
    const { idToken, accessToken } = await this.google.login({});
    const credentials = accessToken
      ? firebase.auth.GoogleAuthProvider.credential(idToken, accessToken)
      : firebase.auth.GoogleAuthProvider.credential(idToken);
    return this.firebaseAuth.signInWithCredential(credentials);
  }

  private async onLoginSuccess(userCredentials: firebase.auth.UserCredential, onSuccess: () => void | Promise<void>) {
    await this.createUserIfNotExist(
      userCredentials.user,
      this.extractAdditionalDataFromUser(userCredentials),
      false,
      onSuccess,
    );
  }

  private shouldIgnoreError(error: any) {
    const webCancelledError =
      error &&
      error.message &&
      (error.message.includes("The popup has been closed") ||
        error.message.includes("due to another conflicting popup"));
    const iosAppleError = error.code === "1001";
    const iosFacebookError = error.errorCode === "4201";
    const iosGoogleError = typeof error === "string" && error.includes("The user canceled the sign-in flow");

    return webCancelledError || iosAppleError || iosFacebookError || iosGoogleError;
  }

  private async userHasRole(roles: Role[]): Promise<boolean> {
    const user = await this.getActiveUser();

    if (!user) {
      return false;
    }

    return roles.includes(user.role);
  }
}
