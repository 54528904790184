import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { Referral } from "src/services/referral/referral.interface";

export const referralQueryParamKey = "referralCode";
export const referralLocalStorageKey = "referralCode";

@Injectable({
  providedIn: "root",
})
export class ReferralService {
  constructor(private readonly firestore: AngularFirestore) {}

  public referralCodeExists(referralCode: string): Observable<boolean> {
    const userCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("referralPromoCode", "==", referralCode.toUpperCase()),
    );

    return userCollection.snapshotChanges().pipe(
      map((actions) => {
        if (actions.length === 0) {
          return false;
        }

        const data = actions[0].payload.doc.data();
        return !!data;
      }),
    );
  }

  public getReferralByUserId(userId: string): Observable<Referral | undefined> {
    const referralCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("userId", "==", userId),
    );

    return referralCollection.snapshotChanges().pipe(
      map((actions) => {
        if (actions.length === 0) {
          return undefined;
        }

        return actions[0].payload.doc.data() as Referral;
      }),
    );
  }

  public getDiscountToApply(referringUserId: string): Observable<number> {
    const referredUsersCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("referredByUserId", "==", referringUserId),
    );

    const referredByCollection = this.firestore.collection(environment.collections.referrals, (ref) =>
      ref.where("userId", "==", referringUserId),
    );

    return combineLatest([referredUsersCollection.snapshotChanges(), referredByCollection.snapshotChanges()]).pipe(
      map((actions) => {
        const percentDiscountPerReferral = 10;
        const referredUsers = actions[0].length;

        const userOwnReferral = actions[1].length > 0 ? (actions[1][0].payload.doc.data() as Referral) : undefined;

        let wasReferredByAUser = false;
        if (userOwnReferral) {
          wasReferredByAUser = !!userOwnReferral.referredByUserId;
        }

        const usersToConsiderForDiscount = wasReferredByAUser ? referredUsers + 1 : referredUsers;

        return Math.min(usersToConsiderForDiscount * percentDiscountPerReferral, 100);
      }),
    );
  }
}
