import { Environment } from "@environments/environment.interface";

export const environment: Environment = {
  production: true,
  appVersion: "1.12.0",
  stripeKey:
    "pk_live_51IA11DCajej8Q89wqIzEjGBXrHagfs5fNeM1brvea4cMgBcvbvpxIyeWafnYaSoF29g3UwxCOTp1F254bIR29Acb00ePDHlp9m",
  phoneNumber: "+1 (970) 743-9996",
  phoneNumberDisplay: "+19707439996",
  planStripePriceId: {
    initial: {
      smallAnnual: "price_1Js97jCajej8Q89wHbmtWTF4",
      smallMonthly: "price_1Jf8X0Cajej8Q89wiI8tzXcQ",
      mediumAnnual: "price_1Js96cCajej8Q89wnMWoUN8V",
      mediumMonthly: "price_1Jf8ZKCajej8Q89woUCPgA8b",
      largeAnnual: "price_1Js94nCajej8Q89w0xaapdzd",
      largeMonthly: "price_1Jf8aACajej8Q89wfZGr0j06",
    },
    september2023: {
      smallAnnual: "price_1Nq3kxCajej8Q89wHXBFBpKo",
      smallMonthly: "price_1Nq3lPCajej8Q89wlsoQAtVi",
      mediumAnnual: "price_1NqPAfCajej8Q89wGjXZwNAU",
      mediumMonthly: "price_1NqPB9Cajej8Q89wY2OSicBF",
      largeAnnual: "price_1Nq3fECajej8Q89wwEpE9CnV",
      largeMonthly: "price_1Nq3jCCajej8Q89whqslaBG1",
    },
    february2024: {
      individualBootBag: "price_1OZJZ4Cajej8Q89wdTnAeupq",
      individualBootBagWithDelivery: "price_1OhbJcCajej8Q89w7rLg8n9R",
      individualLargeSuitcase: "price_1OTxcHCajej8Q89wJptmuDa0",
      individualLargeSuitcaseWithDelivery: "",
    },
    november2024: {
      smallAnnual: "price_1QDD2zCajej8Q89wbeY7rJOi",
      smallMonthly: "price_1QDD2hCajej8Q89w8Z8F32YT",
      mediumAnnual: "price_1QDD7VCajej8Q89wf5WnD0p8",
      mediumMonthly: "price_1QDD7ECajej8Q89wd14kSDbz",
      largeAnnual: "price_1QDD8ECajej8Q89wLUzIUT2Z",
      largeMonthly: "price_1QDD86Cajej8Q89wmAOglRve",
    },
  },
  firebase: {
    apiKey: "AIzaSyCd9NMJXt18AKyPVMo3kMcz669XnpLgYyI",
    authDomain: "clothes-daa4b.firebaseapp.com",
    databaseURL: "https://clothes-daa4b.firebaseio.com",
    projectId: "clothes-daa4b",
    storageBucket: "clothes-daa4b.appspot.com",
    messagingSenderId: "949061229597",
    appId: "1:949061229597:web:6a57b376a9737e85c03af4",
  },
  collections: {
    addresses: "addresses",
    appVersions: "app_versions",
    clothes: "clothes",
    clothes_properties: "clothes_properties",
    driver: "drivers",
    items: "items",
    orders: "orders",
    packlists: "packlists",
    pendingOrders: "pending-orders",
    pendingSubscriptions: "pending-subscriptions",
    plans_options: "plans_options",
    products: "products",
    referrals: "referrals",
    services: "services",
    slides: "slides",
    storage_options: "storage_options",
    subscriptions: "subscriptions",
    subscriptionTypes: "subscriptionTypes",
    users: "users",
    warehouses: "warehouses",
  },
  endpoints: {
    addSource: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-addSource"),
    addUser: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/user-addUser"),
    backendDomain: new URL("https://prod-dot-clothes-daa4b.uc.r.appspot.com"),
    checkIfPaymentRequiredForOrder: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-checkIfPaymentRequiredForOrder",
    ),
    createPaymentIntent: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/payment-createPaymentIntent"),
    createReferralCoupon: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/referral-createReferralCoupon"),
    createSubscription: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-createSubscription"),
    deletePaymentMethod: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/payment-deletePaymentMethod"),
    frontendDomain: new URL("https://app.rocketcloset.com"),
    getAddCardIntent: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-saveInWallet"),
    getCoupon: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getCoupon"),
    getPaymentHistory: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/payment-getPaymentHistory"),
    getSubscriptions: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getSubscriptions"),
    getSubscriptionsForAdmin: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/subscription-getSubscriptions",
    ),
    getWallet: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getWallet"),
    makePaymentForSubscription: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-makePaymentForSubscription",
    ),
    orderDeletionRequest: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/notifications-orderDeletionRequest",
    ),
    paySubscription: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-paySubscription"),
    requestPasswordResetLink: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/password-requestPasswordResetLink",
    ),
    resetPassword: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/password-resetPassword"),
    sendNotification: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/notifications-sendCustomNotificationToUsers",
    ),
    updateSubscription: new URL("https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-updateSubscription"),
    userHasRecentSubscription: new URL(
      "https://us-central1-clothes-daa4b.cloudfunctions.net/userHasRecentSubscription",
    ),
  },
};
