import { Injectable } from "@angular/core";
import { PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { AuthService } from "src/services/auth/auth.service";
import { MessageService } from "src/services/message/message.service";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private readonly authService: AuthService,
    private readonly messageService: MessageService,
  ) {}

  public async listenForNotifications(): Promise<void> {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

    if (!isPushNotificationsAvailable) {
      console.log("Notifications unavailable");
      return;
    }

    console.log("Notifications available");

    await PushNotifications.addListener("registration", async (token) => {
      console.log(token);
      await this.authService.savePushToken(token.value);
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema): Promise<void> => {
        await this.messageService.presentSuccessToastWithTitle(notification.title, notification.body);
      },
    );

    await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
      console.log("Push notification action performed", notification.actionId, notification.inputValue);
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  }
}
