import { Injectable } from "@angular/core";
import { Plan, PlanFrequency, PlanName, PlanUniqueId, SubscriptionType } from "./subscription-type.interface";
import { apiPath } from "../../shared/api-path.const";
import { BackendBody } from "../../shared/backend-api.types";
import { ApiService } from "../http/api.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionTypeService {
  constructor(private readonly apiService: ApiService) {}

  public async getPlanFromIdAndFrequency(planId: PlanUniqueId, frequency: PlanFrequency): Promise<Plan> {
    const allSubscriptionTypes = await this.getAllSubscriptionTypes();

    const targetPlanId = allSubscriptionTypes.find((type) => type.id === planId);

    return targetPlanId?.plans.find((plan) => plan.interval === frequency);
  }

  public async getSubscriptionTypeFromPriceId(priceId: string): Promise<SubscriptionType> {
    const allSubscriptionTypes = await this.getAllSubscriptionTypes();

    for (const type of allSubscriptionTypes) {
      if (type.plans[0].stripeId === priceId) {
        return type;
      }

      if (type.plans[1].stripeId === priceId) {
        return type;
      }
    }

    return undefined;
  }

  public async getAllSubscriptionTypes(): Promise<SubscriptionType[]> {
    const response = await this.apiService.sendGetRequest<BackendBody["SubscriptionTypeDataPage"]>(
      apiPath.subscriptionType,
    );

    return response.subscriptionTypes.map((subscriptionType) => this.toFirestoreSubscriptionType(subscriptionType));
  }

  public getPlanWithPriceIdFromSubscriptionType(subscriptionType: SubscriptionType, priceId: string): Plan {
    if (subscriptionType.plans[0].stripeId === priceId) {
      return subscriptionType.plans[0];
    }

    if (subscriptionType.plans[1].stripeId === priceId) {
      return subscriptionType.plans[1];
    }

    return undefined;
  }

  private toFirestoreSubscriptionType(subscriptionType: BackendBody["ApiSubscriptionType"]): SubscriptionType {
    return {
      id: subscriptionType.firestoreMappingId as PlanUniqueId,
      name: subscriptionType.name as PlanName,
      plans: [
        {
          interval: PlanFrequency.annual,
          price: subscriptionType.annualPrice,
          stripeId: subscriptionType.annualStripeId,
        },
        {
          interval: PlanFrequency.monthly,
          price: subscriptionType.monthlyPrice,
          stripeId: subscriptionType.monthlyStripeId,
        },
      ],
    };
  }
}
