import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { firstValueFrom, Observable } from "rxjs";
import { environment } from "@environments/environment";
import { User, userToString } from "src/services/user/user.interface";
import { Role } from "src/services/user/role.enum";
import { BaseCollectionService } from "src/services/base-collection.service";
import { deleteUndefinedPropertiesFromObject } from "src/services/utils/object.utils";
import { map } from "rxjs/operators";
import { apiPath } from "../../shared/api-path.const";
import { BaseLocation } from "src/services/subscription/base-locations.enum";
import { BackendBody } from "../../shared/backend-api.types";
import { ApiService } from "../http/api.service";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseCollectionService<User> {
  constructor(
    protected readonly firestore: AngularFirestore,
    private readonly apiService: ApiService,
  ) {
    super(firestore, environment.collections.users);
  }

  public getUserByEmail(email: string): Observable<User> {
    return this.getWhereFirst("email", "==", email);
  }

  public async getAllCustomers(): Promise<Observable<User[]>> {
    return (await this.getAll()).pipe(map((users) => users.filter((user) => user.role === Role.customer)));
  }

  public async createNewUser(newUser: User & { baseLocationForDriver?: BaseLocation }): Promise<void> {
    return this.apiService.sendPostRequest<BackendBody["CreateUserArgs"], never>(apiPath.user, newUser);
  }

  public getRoles() {
    return this.firestore.collection("users_roles").doc("roles").valueChanges();
  }

  public async set(doc: User): Promise<string> {
    const userWithIdFromFirebase = { ...doc, createdAt: new Date() };
    const cleanDocWithId = deleteUndefinedPropertiesFromObject(userWithIdFromFirebase);

    await this.firestore
      .collection<User>(environment.collections.users)
      .doc(userWithIdFromFirebase.id)
      .set(cleanDocWithId);

    return userWithIdFromFirebase.id;
  }

  public async search(emailOrName: string): Promise<User[]> {
    const lowerCasedEmailOrName = emailOrName.toLowerCase();
    return (await firstValueFrom(await this.getAll())).filter(
      (user) =>
        user.email?.toLowerCase().includes(lowerCasedEmailOrName) ||
        user.firstName?.toLowerCase().includes(lowerCasedEmailOrName) ||
        user.lastName?.toLowerCase().includes(lowerCasedEmailOrName) ||
        userToString(user).toLowerCase().includes(lowerCasedEmailOrName),
    );
  }

  public async searchCustomers(emailOrName: string): Promise<User[]> {
    return (await this.search(emailOrName)).filter((user) => user.role === Role.customer);
  }
}
