export const redesignRoot = "new";
export const kioskRoot = "kiosk";

export const oldPages = {
  scheduleTrip: "schedule-trip",
};

export const adminPages = {
  allItems: "all-items",
  allOrders: "all-orders",
  customerOnly: "customer-only",
  itemsToHotel: "items-to-hotel",
  itemsToOnboard: "items-to-onboard",
  itemsToStage: "items-to-stage",
  itemsToVehicle: "items-to-vehicle",
  manageKioskMode: "manage-kiosk-mode",
  manageRacks: "manage-racks",
  orderDetails: "order-details",
  scanItems: "scan-items",
  scanRack: "scan-rack",
  scanVehicle: "scan-vehicle",
  stagingSuccess: "staging-success",
  stagingSummary: "staging-summary",
};

export const customerPages = {
  adminOnly: "admin-only",
  downloadTheApp: "download-the-app",
};

export const driverPages = {
  deliverySteps: "delivery-steps",
  orderStepsRoot: "order-steps",
  pickupSteps: "pickup-steps",
};

export const kioskPages = {
  choosePlan: `${kioskRoot}/choose-plan`,
  confirmation: `${kioskRoot}/confirmation`,
  landing: kioskRoot,
  signUp: `${kioskRoot}/signup`,
  thankYou: `${kioskRoot}/thank-you`,
};

export const redesignPages = {
  chooseBase: `${redesignRoot}/choose-base`,
  choosePlan: `${redesignRoot}/choose-plan`,
  deliveries: `${redesignRoot}/deliveries`,
  deliveryDetail: `${redesignRoot}/delivery`,
  enterEmail: `${redesignRoot}/enter-email`,
  homePage: `${redesignRoot}/home`,
  itemDetails: `${redesignRoot}/item-details`,
  landing: redesignRoot,
  login: `${redesignRoot}/login`,
  loginWithPassword: `${redesignRoot}/login-with-password`,
  managePaymentMethods: `${redesignRoot}/manage-payment-methods`,
  myAccount: `${redesignRoot}/my-account`,
  notFound: "not-found",
  payForDelivery: `${redesignRoot}/pay-for-delivery`,
  payForSubscription: `${redesignRoot}/pay-for-subscription`,
  paymentHistory: `${redesignRoot}/payment-history`,
  pickPassword: `${redesignRoot}/pick-password`,
  requestPickup: `${redesignRoot}/request-pickup`,
  register: `${redesignRoot}/register`,
  resetPassword: `${redesignRoot}/reset-password`,
  setInitialPassword: `${redesignRoot}/set-initial-password`,
  signUp: `${redesignRoot}/signup`,
  thankYou: `${redesignRoot}/thank-you`,
};

export const addSubscriptionPage = "add-subscription";
export const editUserPage = "manage-user";
export const manageNotificationsPage = "notifications";
export const myClosetPage = "closet";
export const userDetailPage = "user-details";

const urlPathToRoutePath = (routes: string[]) => {
  return routes.map((route) => route.replace("/", ","));
};

const redesignedPathsSupportingDesktop = [
  redesignPages.choosePlan,
  redesignPages.enterEmail,
  redesignPages.itemDetails,
  redesignPages.login,
  redesignPages.payForDelivery,
  redesignPages.payForSubscription,
  redesignPages.pickPassword,
  redesignPages.requestPickup,
  redesignPages.resetPassword,
  redesignPages.setInitialPassword,
];

const pathsPartOfKioskFlow = Object.values(kioskPages).concat(redesignedPathsSupportingDesktop);
export const routesPartOfKioskFlow = urlPathToRoutePath(pathsPartOfKioskFlow);
