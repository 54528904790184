import { Role } from "src/services/user/role.enum";

export interface User {
  company?: string;
  createdAt?: any;
  email: string;
  id?: string;
  firstName: string;
  hasActiveSubscription?: boolean;
  hasMissingPayment?: boolean;
  hasToSetInitialPassword?: boolean;
  lastName: string;
  overseer?: string;
  phone: string;
  providerId?: string;
  pushToken?: string;
  role: Role;
  stripeId?: string;
  tags?: string[];
  updatedAt?: Date;
}

export interface UserAdditionalData {
  firstName: string;
  lastName: string;
  company?: string;
  phoneNumber: string;
  providerId: string;
}

export function userToString(user: User): string {
  if (!user) {
    return "No user found";
  }

  if (!user.firstName && !user.lastName) {
    return "User without name";
  }

  return `${user.firstName ?? ""} ${user.lastName ?? ""}`;
}

export function isUser(input: object): input is User {
  return "email" in input && "firstName" in input && "lastName" in input;
}
